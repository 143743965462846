import { Injectable } from '@angular/core';
import { mergeMap, Observable } from 'rxjs';
import {
  FilterRequest,
  OrderingRequest,
  ApiListResponse,
  RequestList,
  MessagesApiClientService,
  OffersApiClientService,
  FarmStockOfferRequest,
  MessageRequest,
  MessageType,
  ContinueThreadRequest,
  MessageHeaderDto,
  MessageDto,
  MessageRequestFilter,
  MessageRequestOrder,
} from '@as/api';

@Injectable()
export class OffersService {
  public static ThreadType: string = 'FoodstockOffers';

  constructor(
    private messageProxy: MessagesApiClientService,
    private offersProxy: OffersApiClientService,
  ) {}

  public create(data: FarmStockOfferRequest): Observable<string[]> {
    return this.offersProxy.create(data.farmStockOfferId).pipe(
      mergeMap(offer => {
        return this.sendMessage(data.message, offer, data.title, data.recipientOrganizationId);
      }),
    );
  }

  public sendMessage(message: string, threadId: string, title: string, recipientOrganizationId: string) {
    const messageData: MessageRequest = {
      body: message,
      threadId: threadId,
      threadType: OffersService.ThreadType,
      recipientOrganizationId: recipientOrganizationId,
      title: title,
      type: MessageType.Standard,
    };

    return this.messageProxy.create(messageData);
  }

  public sendMessageInThread(message: string, threadId: string, title: string) {
    const messageData: ContinueThreadRequest = {
      body: message,
      threadId: threadId,
      title: title,
    };

    return this.messageProxy.continueThread(messageData);
  }

  public markOfferAsRead(threadId: string, isReadByBuyer: boolean, isReadBySeller: boolean): Observable<void> {
    return this.offersProxy.markAsRead(threadId, isReadByBuyer, isReadBySeller);
  }

  public getHeaders(requestList: RequestList<FilterRequest, OrderingRequest>): Observable<ApiListResponse<MessageHeaderDto>> {
    return this.offersProxy.get(requestList);
  }

  public getMessages(request: RequestList<MessageRequestFilter, MessageRequestOrder>): Observable<ApiListResponse<MessageDto>> {
    return this.messageProxy.get(request);
  }
}
